<template>
  <section class="client-section sec1-padding">
    <div class="container">
      <div class="row">
        <div class="sec1-title" style="flex:1">
          <h2 class="cpb20">{{ $t('partner.title') }}</h2>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-md-12">
          <div class="clients-carousel">
            <swiper :slides-per-view="5"
                    :space-between="50"
                    :modules="modules"
                    :autoplay="{
                        delay: 3000
                      }"
                    loop
                    @swiper="onSwiper"
                    @slideChange="onSlideChange">
              <swiper-slide class="item">
                <div class="item single-client">
                  <img src="/images/e318b35f-2e36-48ce-bd6c-a5efbde88393.jpg" alt="Partner" draggable="false" class="img-responsive client-img"></div>
              </swiper-slide>
              <swiper-slide class="item">
                <div class="item single-client">
                  <img src="/images/159527b2-0d03-4e25-be83-632133f41b21.jpg" alt="Partner" draggable="false" class="img-responsive client-img">
                </div>
              </swiper-slide>
              <swiper-slide class="item">
                <div class="item single-client">
                  <img src="/images/efe6039e-c25e-4f7a-8f7c-7d41aa151eda.png" alt="Partner" draggable="false" class="img-responsive client-img">
                </div>
              </swiper-slide>
              <swiper-slide class="item">
                <div class="item single-client">
                  <img src="/images/c16e8d43-416d-4f7f-b67d-f4155a570136.png" alt="Partner" draggable="false" class="img-responsive client-img">
                </div>
              </swiper-slide>
              <swiper-slide class="item">
                <div class="item single-client">
                  <img src="/images/5bf97b27-2990-4133-9359-a4cf4f2b6312.png" alt="Partner" draggable="false" class="img-responsive client-img">
                </div>
              </swiper-slide>
              <swiper-slide class="item">
                <div class="item single-client">
                  <img src="https://www.patakapay.com/upload/2022/06/21/490df4e2-a880-4523-af7c-3b7105b95dd5.png" alt="Partner" draggable="false" class="img-responsive client-img">
                </div>
              </swiper-slide>
              <swiper-slide class="item">
                <div class="item single-client">
                  <img src="https://www.patakapay.com/upload/2022/06/21/91260fb0-e7d0-44f4-9609-baf6f4cee6fc.png" alt="Partner" draggable="false" class="img-responsive client-img">
                </div>
              </swiper-slide>
              <swiper-slide class="item">
                <div class="item single-client">
                  <img src="/images/6de40a12-358d-4d0e-bd1b-f342091b7afa.png" alt="Partner" draggable="false" class="img-responsive client-img">
                </div>
              </swiper-slide>
              <swiper-slide class="item">
                <div class="item single-client">
                  <img src="/images/fab9f873-4b74-4c4e-abf5-e2895fb06590.png" alt="Partner" draggable="false" class="img-responsive client-img">
                </div>
              </swiper-slide>
              <swiper-slide class="item">
                <div class="item single-client">
                  <img src="/images/ca0c6618-918b-4ce1-9e70-5fecfb9babbd.png" alt="Partner" draggable="false" class="img-responsive client-img">
                </div>
              </swiper-slide>
              <swiper-slide class="item">
                <div class="item single-client">
                  <img src="/images/b6b48ef9-ac53-4dc1-b940-1ef4d5b01b88.png" alt="Partner" draggable="false" class="img-responsive client-img">
                </div>
              </swiper-slide>
              <swiper-slide class="item">
                <div class="item single-client">
                  <img src="/images/09898d6d-cd87-43e7-99d9-36d5be80afb8.png" alt="Partner" draggable="false" class="img-responsive client-img">
                </div>
              </swiper-slide>
              <swiper-slide class="item">
                <div class="item single-client">
                  <img src="/images/5e362fe9-2c5e-4d6b-bb00-fc322b19aff7.png" alt="Partner" draggable="false" class="img-responsive client-img">
                </div>
              </swiper-slide>
              <swiper-slide class="item">
                <div class="item single-client">
                  <img src="/images/e40b4071-ac45-4084-83a5-3d4306f14d08.png" alt="Partner" draggable="false" class="img-responsive client-img">
                </div>
              </swiper-slide>
              <swiper-slide class="item">
                <div class="item single-client"><img
                    src="/images/2bc1b7ed-41ff-4d61-a8a6-d47f1a2db70d.png"
                    alt="Partner" draggable="false"
                    class="img-responsive client-img"></div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'
// Import Swiper styles
import 'swiper/swiper.css'
export default {
  name: "CustomerView",
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, Autoplay],
    };
  },
}
</script>

<style scoped>

</style>